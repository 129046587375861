@import "vars";
@import "bootstrap-5.3.2/scss/bootstrap.scss";

@font-face {
  font-family: 'Suisse';
  src:  url('/assets/fonts/SuisseIntl-Bold-WebXL.woff2') format('woff2'),
        url('/assets/fonts/SuisseIntl-Bold-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse-book';
  src:  url('/assets/fonts/SuisseIntl-Book-WebXL.woff2') format('woff2'),
        url('/assets/fonts/SuisseIntl-Book-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'RSA';
  src:  url('/assets/fonts/RSA-Regular.woff2') format('woff2'),
        url('/assets/fonts/RSA-Regular.woff') format('woff');
}

html {
  font-family: Suisse;
  font-size: 11pt;
  line-height: 13.5pt;
}

body {
  font-family: Suisse;
  font-size: 1rem;
  line-height: 13.5pt;
  background-color: white;
}

.container-fluid {
  padding: 0;
}

.row {
  margin: 0;
  padding: 0;
}

.contenu-page {
  //background-color: aqua;
  padding: 0;
}

.col-contenu {
  margin-right: 0;
  background-color: $couleur-fond;

  .cat-name {
    color: white;
    padding-bottom: 1rem;
  }

}

.marge {
  background-color: $couleur-fond;
}

a {
  text-decoration: none;
  color: black;
}

.marge1 {
    display: none;
}
.marge2 {
    display: none;
}
.marge2-2 {
  display: none;
}
.marge3 {
    display: none;
}

.col-fond {
  display: none;
}

.projet-index {
  margin-bottom: 1rem;
}

.index-picture {
  padding-bottom: 1rem;
}


.col-top-1 {
  display: none;
}

.col-top-2 {
  display: none;
}

.marge-top-1 {
  display: none;
}

.marge-top-2 {
  display: none;
}

@include media-breakpoint-up(sm) {
  .col-contenu {
    margin-right: 1rem;
  }
  .marge1 {
    display: block;
  }
  .marge2 {
    display: block;
  }
  .marge2-2 {
    display: block;
  }
  .col-fond {
    display: block;
  }  
  .marge3 {
    display: block;
  }

  .col-top-1 {
    display: block;
  }
  .col-top-2 {
    display: none;
  }
  .marge-top-1 {
    display: block;
  }
  .marge-top-2 {
    display: block;
  }
}

@include media-breakpoint-up(xl) {
  .marge1 {
    display: none;
  }
  .marge2 {
    display: none;
  }
  .marge2-2 {
    display: none;
  }
  .col-fond {
    display: none;
  }  
  .marge3 {
    display: block;
  }

  .marge-top-1 {
    display: none;
  }
  .marge-top-2 {
    display: block;
  }

  .col-top-1{
    display: block;
  }
  
  .col-top-2{
    display: block;
  }
}

.rsa-top {
  padding-top: 0.5rem;
  padding-bottom: 8rem;
}

.rsa-nav {
  text-align: right;
}


.nav-item {
  //display: block;
  padding: 0;
}

.top-nav {
  position: fixed;
  right: 2rem;
  padding-top: 0.5rem;
}

.rsa-menu {
  font-size: 1rem;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(-180deg);
  padding-bottom: 0.5rem;
  }

.rsa-logo {
    font-family: RSA;
    font-size: 2rem;
    //ine-height: 2rem;
    padding-top: 0.5rem;
    position: fixed;
    bottom: 0;
    right: 1rem;
}

.titre-projet-index {
  padding-bottom: 0.5rem;
}


///// Pages Projet

.titre-projet {
  font-size: 1rem;
}

.sous-titre-projet {
  font-family: Suisse-Book;
  font-size: 1rem;
  padding-bottom: 1rem;
}

.plan-masse {
  padding-bottom: 1rem;
}

.marge-projets-1 {
  display: none;
}

.marge-projets-2 {
  display: none;
}

.marge-projets-3 {
  display: none;
}

.infos-1 {
  display: none;
}
.infos-2 {
  display: block;
  padding-bottom: 1rem;
}


.visuels-projet {
  .image {
    padding-bottom: 1rem;
  }
}

.image-index {
  margin-bottom: 1rem;
}

.image-schema {
  border: 1pt;
  margin-bottom: 1rem;
}

.visuels-index-projet {
  padding-bottom: 1rem;
}


.description-projet {
    font-family: Suisse-Book;
    padding-bottom: 2rem;
  
    .titre-paragraphe {
      font-family: Suisse;
    }
}

.nav-projets {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}  


@include media-breakpoint-up(sm) {
  .titre-projet {
    font-size: 2rem;
    line-height: 2rem;
  }

  .sous-titre-projet {
    font-size: 2rem;
    line-height: 2rem;
    padding-bottom: 2rem;
  }

  .marge-projets-1 {
    display: block;
  }

  .marge-projets-2 {
    display: block;
  }

  .marge-projets-3 {
    display: block;
  }

  .infos-1 {
    display: block;
  }
  .infos-2 {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .marge-projets-2 {
    display: none;
  }
  .visuels-index-projet {
    margin-right: 1rem;
  }
}

.plan-masse-infos {
  padding-bottom: 1rem;
}

.cat {
  font-size: 1rem;
}

.info {
  font-family: Suisse-Book;
  padding-bottom: 0.5rem;
  margin-right: 2rem;
}

.plan-masse {
  padding-bottom: 1rem;
  margin-right: 2rem;
}

.infos-1 {
  padding-top: 1rem;
}

.col-image-index {
  padding-right: 1rem;
  padding-bottom: 1rem;
  .image {
    padding-bottom: 1rem;
  }
}

.infos-copyrights {
  text-align: right;
  font-family: Suisse-Book;
  color: $couleur-fond;
}

.plans-projet {
  padding-top: 5rem;
}

///// Page agence

.agence {

  .infos {
    padding-bottom: 3rem;
  }

  .chapo {
  }

  h1 {
    font-size: 1rem;
    line-height: 1rem;
    padding-bottom: 1rem;
    padding-top: 5rem;
  }

  h2 {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0;
  }

  p {
    padding-top: 0;
    font-family: Suisse-Book;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-family: Suisse-Book;
    padding-bottom: 0.5rem;
  }

  .moyens {
    ul {
      margin-bottom: 1rem;
    }
    li {
      padding-bottom: 0;
    }
  }
  
}

@include media-breakpoint-up(sm) {
  .agence {
    h1 {
      font-size: 2rem;
      line-height: 2rem;
      padding-bottom: 2re
    }
  }
}
